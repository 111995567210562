import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    Box, Button, Typography,
    Card,
    CardContent,
    LinearProgress,
    styled
} from "@mui/material";
import { Container, Row, Col } from "reactstrap";
import Modal from 'react-modal';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Analysis = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleBack = () => {
        navigate(-1);
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const BorderLinearProgress = styled(LinearProgress)({
        height: 8,
        borderRadius: 4,
        backgroundColor: '#e0e0e0',
        '& .MuiLinearProgress-bar': {
            borderRadius: 4,
            backgroundColor: '#4caf50',
        },
    });

    const speakingData = [
        { label: "Read Aloud", score: 60 },
        { label: "Repeat Sentence", score: 30 },
        { label: "Describe Image", score: 52 },
        { label: "Retell Lecture", score: 10 },
        { label: "Short Answer", score: 15 }
    ];

    const writingData = [
        { label: "Essay", score: 30 },
        { label: "Summarize Text", score: 59 }
    ];

    const listeningData = [
        { label: "Summarize Text", score: 60 },
        { label: "Multiple Answers", score: 79 },
        { label: "Fill in the Blanks", score: 60 },
        { label: "Highlight Summary", score: 60 },
        { label: "Single Answer", score: 99 },
        { label: "Missing Words", score: 60 },
        { label: "Incorrect Words", score: 60 },
        { label: "Write From Dictation", score: 60 },
    ];

    const readingData = [
        { label: "R/W Fill in Blanks", score: 60 },
        { label: "Multiple Answers", score: 79 },
        { label: "Reorder Paragraph", score: 59 },
        { label: "Fill in Blanks", score: 60 },
        { label: "Single Answer", score: 99 },
    ];

    const [progress, setProgress] = useState({
        ...speakingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
        ...writingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
        ...listeningData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
        ...readingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {})
    });

    useEffect(() => {
        const allData = [...speakingData, ...writingData, ...listeningData, ...readingData];
        allData.forEach(item => {
            setTimeout(() => {
                setProgress(prev => ({
                    ...prev,
                    [item.label]: item.score
                }));
            }, 300);
        });
    }, []);

    const ProgressCard = ({ title, data }) => (
        <Card sx={{ height: '100%', boxShadow: "4px 4px #0000007a" }}>
            <CardContent>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    {title}
                </Typography>
                <Box sx={{ mt: 3 }}>
                    {data.map((item) => (
                        <Box key={item.label} sx={{ mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                    {item.label}
                                </Typography>
                                <Typography variant="body2" color="text.primary">
                                    {progress[item.label]}
                                </Typography>
                            </Box>
                            <BorderLinearProgress
                                variant="determinate"
                                value={progress[item.label]}
                            />
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <Container className="" fluid>
            <Row className="border-bottom shadow py-lg-2 py-2" style={{ backgroundColor: "cadetblue", verticalAlign: "middle !important" }}>
                <Col xl="2">
                    <Button variant="contained" sx={{ backgroundColor: "#1C1E29 !important" }} style={{ marginTop: "13px" }} onClick={handleBack}>
                        <ArrowBackIosIcon /> Back
                    </Button>
                </Col>

                <Col xl="8" className="planon">
                    <h2 className="text-center text-white display-3">
                        Analysis for Attempt {id}
                    </h2>
                </Col>

                <Col xl="2">
                </Col>
            </Row>

            <Row className=" shadow py-lg-2 py-2" style={{ backgroundColor: "cadetblue" }}>
                <Col xl="12">
                    <h1 className="text-center text-white">
                        Categories
                    </h1>
                </Col>
            </Row>

            <Row className="shadow py-lg-4 py-4" style={{ backgroundColor: "cadetblue" }}>
                <Col xl="3" className="order-sm-2 planon mb-3">
                    <ProgressCard title="Speaking" data={speakingData} />
                </Col>

                <Col xl="3" className="order-sm-2 planon mb-3">
                    <ProgressCard title="Writing" data={writingData} />
                </Col>

                <Col xl="3" className="order-sm-2 planon mb-3">
                    <ProgressCard title="Reading" data={readingData} />
                </Col>

                <Col xl="3" className="order-sm-2 planon mb-3">
                    <ProgressCard title="Listening" data={listeningData} />
                </Col>
            </Row>

            <Row className="border-top shadow py-lg-2 py-2" style={{ backgroundColor: "cadetblue" }}>
                <Col xl="12" className="planon">
                    <h1 className="text-center text-white">
                        Questions
                    </h1>
                </Col>
            </Row>

            <Row className="shadow py-lg-4 py-4 d-flex justify-content-center" style={{ backgroundColor: "cadetblue" }}>

                <Row style={{ backgroundColor: "#1c1e29", borderRadius: "8px", padding: "10px", width: "90%" }} className="align-items-center mb-4">
                    <Col xl="6">
                        <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                            #1 Speaking Read Aloud
                        </Typography>
                    </Col>

                    <Col xl="6" className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "green", color: "white", borderRadius: "8px", fontWeight: "bold", paddingX: "40px" }}
                            onClick={openModal}
                        >
                            59/90
                        </Button>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: "#1c1e29", borderRadius: "8px", padding: "10px", width: "90%" }} className="align-items-center mb-4">
                    <Col xl="6">
                        <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                            #1 Speaking Repeat Sentence
                        </Typography>
                    </Col>

                    <Col xl="6" className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "green", color: "white", borderRadius: "8px", fontWeight: "bold", paddingX: "40px" }}
                        >
                            09/90
                        </Button>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: "#1c1e29", borderRadius: "8px", padding: "10px", width: "90%" }} className="align-items-center mb-4">
                    <Col xl="6">
                        <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                            #1 Speaking Describe Image
                        </Typography>
                    </Col>

                    <Col xl="6" className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "green", color: "white", borderRadius: "8px", fontWeight: "bold", paddingX: "40px" }}
                        >
                            52/90
                        </Button>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: "#1c1e29", borderRadius: "8px", padding: "10px", width: "90%" }} className="align-items-center mb-4">
                    <Col xl="6">
                        <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                            #1 Speaking Retell Lecture
                        </Typography>
                    </Col>

                    <Col xl="6" className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "green", color: "white", borderRadius: "8px", fontWeight: "bold", paddingX: "40px" }}
                        >
                            78/90
                        </Button>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: "#1c1e29", borderRadius: "8px", padding: "10px", width: "90%" }} className="align-items-center mb-4">
                    <Col xl="6">
                        <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                            #1 Speaking Short Answer
                        </Typography>
                    </Col>

                    <Col xl="6" className="d-flex justify-content-end">
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "green", color: "white", borderRadius: "8px", fontWeight: "bold", paddingX: "47px" }}
                        >
                            1/1
                        </Button>
                    </Col>
                </Row>


                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Read Aloud Modal"
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#2d2f3a',
                            color: '#d1d5db',
                            borderRadius: '8px',
                            padding: '20px',
                            width: '50%',
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        },
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <div className='d-flex justify-content-between'>
                            <Typography variant="h5" sx={{ color: "#d1d5db", marginBottom: '20px' }}>
                                Read Aloud
                            </Typography>

                            <Button onClick={closeModal} style={{ backgroundColor: 'red', color: 'white', padding:"2px 15px !important" }}>
                                Close
                            </Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                            <div>
                                <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                    Score
                                </Typography>
                                <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                    59/90.0
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                    Content
                                </Typography>
                                <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                    72/90
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                    Pronun
                                </Typography>
                                <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                    63/90
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                    Fluency
                                </Typography>
                                <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                    58/90
                                </Typography>
                            </div>
                        </div>
                        <div style={{ backgroundColor: '#1c1e29', borderRadius: '8px', padding: '15px', marginBottom: '15px' }}>
                            <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                Question Response
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                45
                            </Typography>
                        </div>
                        <div style={{ backgroundColor: '#1c1e29', borderRadius: '8px', padding: '15px', marginBottom: '15px' }}>
                            <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                                Write from dictation
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#d1d5db" }}>
                                50
                            </Typography>
                        </div>

                    </div>
                </Modal>


            </Row>
        </Container>


    );
};

export default Analysis;