import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const Result = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={{
                backgroundColor: "#181B2A",
                minHeight: "100vh",
                color: "white",
                padding: "20px",
            }}
        >
            <Typography variant="h5" align="center" gutterBottom>
                Result for Attempt {id}
            </Typography>
            <Typography variant="body1" gutterBottom>
                Detailed result for attempt ID: {id} will be shown here.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleBack}>
                Back
            </Button>
        </Box>
    );
};

export default Result;
