import React, { useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import { ToastContainer } from "react-toastify";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import AttemptHistory from "components/AttemptHistory/attempthistory";
import Analysis from "components/AttemptHistory/analysis";
import Result from "components/AttemptHistory/result";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSessionExpiry = () => {
      const loginTime = localStorage.getItem("loginTime");
      const currentTime = new Date().getTime();
      const sessionDuration = 8 * 60 * 60 * 1000; // 8 hours

      if (loginTime && currentTime - loginTime > sessionDuration) {
        localStorage.removeItem("loginTime");
        navigate("/auth/login");
      }
    };

    checkSessionExpiry();
    const interval = setInterval(checkSessionExpiry, 60 * 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  const [students, setStudents] = useState([]);

  const fetchStudents = async () => {
    try {
      const res = await getApi(API_PATH.ADMIN.GET_ADMINSTUDENT);
      if (res.status === 200) {
        setStudents(res.data || []); 
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <>
    <ToastContainer/>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/admin/*" element={<AdminLayout data={students} />} />

        <Route path="/attempthistory" element={<AttemptHistory />} />
        <Route path="/analysis/:id" element={<Analysis />} />
        <Route path="/result/:id" element={<Result />} />

        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </>
  );
}

export default App;
