export const API_PATH = {
  ADMIN: {
    LOGIN: "v2/admin/login",
    REMOVE_STUDENT: "v2/admin/removeStudent",
    ADD_STUDENTS: "v2/admin/addStudent",
    GET_ADMINSTUDENT: "v2/admin/getme",
    LOGINON_0FF_STUDENTS: "v2/admin/toggleLogin",
    DELETE_ALLSTUDENT: "v2/admin/deleteAllStudents",
    ENABLE_ALLLOGIN: "v2/admin/enableStudentsLogins",
    UPDATE_ADMINSTUDENT: "v2/admin/updateStudentCred",
    DISABLE_STUDENTLOGIN: "v2/admin/disableStudentsLogins",
    TOGGLE_STUDENT_LOGIN: "v2/admin/toggleLogin",
    ADD_SUBSCRIPTION: "v2/admin/addSubscription",

    CREATE_PAYMENT : "v2/admin/create-order",
    VERIFY_PAYMENT : "v2/admin/verify-payment",

    ATTEMPT_hISTORY : "v2/student/save-mock-test-attempts",
    
  },
};
