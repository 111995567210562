
import axios from "axios";
import { useNavigation } from "../utils/NavigationContext";

let customAxios = axios.create({
  // baseURL: 'http://13.126.111.251/v2/' ,
   baseURL : 'https://creativegwpte.in/'
  // baseURL : 'http://localhost:5000/'
});


customAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    if (token ) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            const navigate = useNavigation();
            // localStorage.clear();
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

export async function getApi(path, data) {
  return await customAxios.get(`/${path}`);
}
export async function postApi(path, data) {
  return await customAxios.post(`/${path}`, data);
}
export async function putApi(path, data) {
  return await customAxios.put(`/${path}`, data);
}
export async function deletetApi(path, data) {
  return await customAxios.delete(`/${path}`, data);
}
